export default function translations () {
  const transText = `Original,Language,Translated,Translated Plural
Your teammates will join via a link given to you in the game lobby,it,I tuoi compagni di squadra si uniranno tramite un link che ti verrà fornito nella pagina iniziale del gioco,
I am playing as a team,it,Voglio giocare come squadra,
Continue,it,Continua,
Back,it,Torna indietro,
Close,it,Chiudi,
Invite,it,Invita,
Would you like to skip this task?,it,Vuoi saltare questo compito?,
You will still get,it,Riceverai comunque,
to complete the chapter,it,per completare la prova,
", but no points will be added to the leaderboard.",it,", ma nessun punto verrà aggiunto alla classifica",
View leaderboard,it,Visualizza la classifica,
You can re-enter the game at any point of time by going back to the lobby via the shared link.,it,Puoi rientrare nel gioco in qualsiasi momento tornando alla pagina iniziale tramite il link condiviso,
Leave game,it,Lascia il gioco,
Close,de,Schliessen,
Next,de,Weiter,
Click here to start,de,Spiel starten,
Re-join game,de,Wieder&nbspteilnehmen,
You are currently in,de,Ihr befindet Euch gerade in,
Click here to join,de,Teilnehmen,
Enter a game code,de,Spielcode eingeben.,
Go,de,Weiter,
Scan a game QR code,de,QR-Code scannen,
Click here to start camera,de,Kamera starten,
Scanned value will appear here,de, ,
more needed,de,weitere benötigt.,
Go into the circle to access the task.,de,"Geht weiter, bis Ihr in den Kreis kommt, um auf die Aufgabe zugreifen zu können.",
It may take up to 3 mins to update location.,de,"󠀠󠀠󠀠",
Location for this task is hidden.,de,Der Speicherort für diese Aufgabe ist ausgeblendet.,
Message from the Facilitator,de,Nachricht vom Spielleiter,
Send image instead,de,Bild&nbspsenden,
Chat,de,Chat,
Loading...,de,Laden...,
Tasks,de,Aufgabe,
Remove image and re-upload?,de,Bild entfernen und erneut hochladen?,
Scroll down to see tasks,de,"Nach unten scrollen, um Aufgaben anzuzeigen",
Click to go to latest chapter,de,Zum nächsten Kapitel,
Scroll down to start,de,"Nach unten scrollen, um zu starten",
Click here to start game,de,Spiel starten,
Say something...,de,Text...,
You can re-enter the game at any point of time by going back to the lobby via the shared link.,de,"Ihr könnt das Spiel jederzeit neu starten, indem Ihr über den freigegebenen Link zur Anmeldung zurückkehrt.",
"You must click ALLOW if asked for location access. Else, you will not be able to proceed.",de,"Wenn Ihr aufgefordert werdet, den Standortzugriff zu erleben, bitte auf „ZULASSEN“ klicken. Anderenfalls könnt Ihr nicht fortfahren.",
"Once your location is shown correctly on your maps app, come back to this page & press 'Update location'.",de,Sobald Ihr Standort in Ihrer Karten-App korrekt angezeigt wird, kehren Sie auf diese Seite zurück und drücken Sie „Standort aktualisieren“.,
To Faci,de,an_Spielleiter
Buy hint,de,"Ja, Hinweis für Münzen kaufen."
Would you like to buy a hint,de,"Möchtet Ihr einen Hinweis für diese Aufgabe kaufen? Ihr habt für das gesamte Spiel 5 Münzen."
Close,ro,Închide,`

  const rows = transText.split('\n')
  const dataList = []

  for (let i = 1; i < rows.length; i++) {
    const cells = rows[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).map(cell => cell.replace(/"/g, ''))
    const rowData = {
      original: cells[0].trim(),
      language: cells[1].trim(),
      translated: cells[2].trim(),
    }
    dataList.push(rowData)
  }

  return dataList
}
